<template>
  <h1 class="text-2xl font-semibold text-gray-900">Nieuwsartikels</h1>
  <div class="py-4 max-w-6xl">
    <UITableResponsive :items="items" :headers="['Thumbnail', 'Date', 'Keyword', 'Article', '']">
      <template v-slot:item-Thumbnail="{ item }">
        <img :src="item.image" :alt="item.title" class="h-16 w-20 mr-auto md:mr-0">
      </template>
      <template v-slot:item-Date="{ item }">
        <span class="block mt-2 md:mt-0 bold">{{ dateTimeStringLocale(item.date) }}</span>
      </template>
      <template v-slot:item-Keyword="{ item }">
        <span class="font-bold">{{ uppercase(item.matched_word) }}</span>
      </template>
      <template v-slot:item-Article="{ item }">
        <a :href="item.link" target="_blank" rel="noopener noreferer nofollow" class="text-blue-700 hover:underline">
          <TextTruncate :length="75">{{ item.title }}</TextTruncate>
          <small class="text-gray-500 italic text-sm block">{{ getHost(item.link) }}</small>
        </a>
        <small></small>
      </template>
      <template v-slot:item-="{ item }">
        <button class="btn info" @click="useNotification(item.description)">Beschrijving lezen</button>
      </template>
    </UITableResponsive>
    <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
  </div>
</template>

<script setup>
import { watch, ref, defineAsyncComponent } from 'vue'
import UITableResponsive from '@/components/UI/Table/Responsive'
import TextTruncate from '@/components/UI/Text/Truncate'
import usePaginatedApi from '@/hooks/usePaginatedApi'
import useNotification from '@/hooks/useNotification'
import { dateTimeStringLocale } from '@/functions/formatDate'
import { uppercase } from '@/functions/formatText'

const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const filters = ref({
  action: null,
  user_id: null,
  rit_id: null,
})

const { limit, offset, count, items, getData, reset } = usePaginatedApi('/api/dashboard/nieuwsartikels/list', filters)
limit.value = 20

const getHost = (link) => new URL(link).host

watch(
  () => filters.value,
  () => {
    reset()
    getData()
  },
)
</script>
